export type SwarovskiOptikSegmentKey = "hunting" | "birding" | "outdoor";

export type SwarovskiOptikSegment = {
  id: string;
  key: SwarovskiOptikSegmentKey;
  slug: string;
  headerId: string;
  homePageId: string;
  searchPageId: string;
  cartPageId: string;
  checkoutPageId: string;
  purchaseConfirmationPageId: string;
  storiesOverviewPageId: string;
  videoStoriesOverviewPageId: string;
  dealerLocatorPageId: string;
  newsletterLandingPageId: string;
  eventsOverviewPageId: string;
  pressReleasesPageId: string;
  privacyPolicyPageId: string;
  termsAndConditionsPageId: string;
  educationalStoriesOverviewPageId: string | undefined;
};

export type SwarovskiOptikSegmentShort = {
  id: string;
  key: SwarovskiOptikSegmentKey;
  slug: string;
};

export function parseSwarovskiOptikSegment(keyStr: string): keyStr is SwarovskiOptikSegmentKey {
  // FIXME: Use validator from storefront
  return ["hunting", "birding", "outdoor"].includes(keyStr);
}
