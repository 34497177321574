// https://docs.adyen.com/online-payments/web-components/localization-components#supported-languages

export function getAdyenShopperLocale(languageIsoCode: string): string {
  return adyenSupportedLocalesByLanguageMap.get(languageIsoCode.toLowerCase()) || adyenFallbackLocale;
}

const adyenFallbackLocale = "en-US";

const adyenSupportedLocales = [
  { label: "Arabic - International", code: "ar" },
  { label: "Chinese - Simplified", code: "zh-CN" },
  { label: "Chinese - Traditional", code: "zh-TW" },
  { label: "Croatian", code: "hr-HR" },
  { label: "Czech", code: "cs-CZ" },
  { label: "Danish", code: "da-DK" },
  { label: "Dutch", code: "nl-NL" },
  { label: "English - US", code: "en-US" },
  { label: "Finnish", code: "fi-FI" },
  { label: "French", code: "fr-FR" },
  { label: "German", code: "de-DE" },
  { label: "Greek", code: "el-GR" },
  { label: "Hungarian", code: "hu-HU" },
  { label: "Italian", code: "it-IT" },
  { label: "Japanese", code: "ja-JP" },
  { label: "Korean", code: "ko-KR" },
  { label: "Norwegian", code: "no-NO" },
  { label: "Polish", code: "pl-PL" },
  { label: "Portuguese", code: "pt-BR" },
  { label: "Romanian", code: "ro-RO" },
  { label: "Russian", code: "ru-RU" },
  { label: "Slovak", code: "sk-SK" },
  { label: "Slovenian", code: "sl-SI" },
  { label: "Spanish", code: "es-ES" },
  { label: "Swedish", code: "sv-SE" },
];

const adyenSupportedLocalesByLanguageMap = new Map(
  adyenSupportedLocales.flatMap(l => {
    const languageCodeMatch = l.code.match(/^([a-z]+)-/);
    const languageCode = languageCodeMatch ? languageCodeMatch[1] : null;

    if (languageCode) {
      return [[languageCode, l.code] as const];
    } else {
      return [];
    }
  }),
);
