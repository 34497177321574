import type { Locale } from "contentful";
import type { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";

export type SwarovskiOptikShopMode = (
  | // Represents a locale with an active shop:
  {
      tag: "opened";
    }

  // Represents a locale with an active shop that is temporarily closed
  // for a short period of time (i.e. due to technical maintenance or similar reasons):
  | { tag: "temporarily-closed" }

  // Represents a locale with an active shop that is closed for a longer period
  | { tag: "operational-break" }

  // Represents a locale without an active shop:
  | { tag: "inactive" }

  // custom mode
  | { tag: "custom" }
) &
  SwarovskiOptikShopModeCapabilities;

export type SwarovskiOptikShopModeCapabilities = {
  // Newsletter doesn't quite fit here
  // but... newsletter languages are all languages of shop-enabled locales
  isNewsletterLanguage: boolean;
  availabilitySubscription: "enabled" | "disabled" | "force-enabled";
  cart: "enabled" | "hidden" | "disabled";
  pdpMainAction: "cart" | "availability-subscription" | "dealer-locator";
};

type Country = {
  label: string;
  isoCode: string;
};

export type SwarovskiOptikLocale = {
  code: string;
  shopMode: SwarovskiOptikShopMode;
  availablePaymentMethods: Array<{ url: string; title: string }>;
  chatbotEnabled: boolean;
  currency: string;
  country: "INTERNATIONAL" | Country;
  language: {
    label: string;
    isoCode: string;
  };
  contentful: Locale;
  segments: NonEmptyArray<{ key: string }>;
  distanceUnit: "metric" | "imperial";
};

export type SwarovskiOptikShopLocale = Omit<SwarovskiOptikLocale, "country"> & { country: Country };
export type SwarovskiOptikInternationalLocale = Omit<SwarovskiOptikLocale, "country"> & { country: "INTERNATIONAL" };

export const INTERNATIONAL_ISO_CODE_VALUE = "INT";

export function getIsoCodeWithIntFallback(localeCountry: SwarovskiOptikLocale["country"]): string {
  if (localeCountry === "INTERNATIONAL") {
    return INTERNATIONAL_ISO_CODE_VALUE;
  } else {
    return localeCountry.isoCode;
  }
}
