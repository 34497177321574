import * as t from "io-ts";
import type { NonEmptyArray } from "fp-ts/es6/NonEmptyArray";

const CloudinaryRefCommonFields = t.type({
  public_id: t.string,
  version: t.number,
  created_at: t.string,
  tags: t.array(t.unknown),
  bytes: t.number,
  type: t.string,
  url: t.string,
  secure_url: t.string,
  metadata: t.unknown, // can be {} for subtitles
  original_url: t.union([t.string, t.undefined]),
  original_secure_url: t.union([t.string, t.undefined]),
  raw_transformation: t.union([t.string, t.undefined, t.null]),
});

export const CloudinaryVideoRef = t.array(
  t.intersection([
    t.type({
      width: t.number,
      height: t.number,
      format: t.string,
      resource_type: t.literal("video"),
      duration: t.union([t.number, t.null, t.undefined]),
    }),
    CloudinaryRefCommonFields,
  ]),
);

export const CloudinarySubtitlesRef = t.array(
  t.intersection([
    t.type({
      resource_type: t.literal("raw"),
    }),
    CloudinaryRefCommonFields,
  ]),
);

export type CloudinaryVideoType = t.TypeOf<typeof CloudinaryVideoRef>[0];
export type CloudinarySubtitlesType = t.TypeOf<typeof CloudinarySubtitlesRef>[0];

type Video = {
  url: string;
  type: string;
};

export type VideoResolutions = {
  variants: NonEmptyArray<{ width: number; video: Video; poster: string }>;
  original: Video & {
    width: number;
    height: number;
  };
};

export type VideoFormats = {
  mp4: VideoResolutions;
};

export type VideoType = {
  __typename: "UtilityVideo";
  video: {
    public_id: string;
    tags: Array<any>;
    metadata: unknown;
    bytes: number;
    duration: number | undefined;
    url: string;
    secure_url: string;
    format: string;
    derived_formats: VideoFormats;
    width: number;
    height: number;
    poster: {
      url: string;
    };
  };
  isLifestyleAsset: boolean | undefined;
  subtitles?: CloudinarySubtitlesType;
};
