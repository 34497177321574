import "focus-visible/dist/focus-visible.min";
import "./utils/development/why-did-you-render";

//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import { CacheProvider, Theme } from "@emotion/react";
import createCache from "@emotion/cache";

import { PublicConfigurationT } from "@swo/shared";
import { Interpolation } from "@emotion/styled";
import { getWindowConfig } from "./domain/contentful/get-window-config";

export type AppProps = { config: PublicConfigurationT; runtimeMode: "ssr" | "spa" };

declare module "react" {
  interface Attributes {
    css?: Interpolation<Theme>;
  }
}

function importBuildTarget(): Promise<{ readonly default: (props: AppProps) => JSX.Element }> {
  smoothscroll.polyfill();

  // This is for iOS <= 13.3
  // We need this because @vani still uses one :seenoevil:
  (async () => {
    if ("ResizeObserver" in window === false) {
      // Loads polyfill asynchronously, only if required.
      const module = await import("@juggle/resize-observer");
      //@ts-expect-error
      window.ResizeObserver = module.ResizeObserver;
    }
  })();

  if (process.env.REACT_APP_BUILD_TARGET === "editors-app") {
    console.log(`Editors app extension build.`);

    return import("./EditorsApp");
  } else {
    console.log(`Public frontend build.`);

    return import("./App");
  }
}

const windowConfigPromise: Promise<PublicConfigurationT> = getWindowConfig();
const buildTarget = importBuildTarget();

buildTarget
  .then(target => {
    return target.default;
  })
  .then(MainApp => {
    windowConfigPromise
      .then(config => {
        const el = document.getElementById("root");

        if (document.getElementById("root")?.childElementCount) {
          const cache = createCache({ key: "swo-css" });

          ReactDOM.hydrate(
            <CacheProvider value={cache}>
              <MainApp config={config} runtimeMode={"ssr"} />
            </CacheProvider>,
            el,
          );
        } else {
          ReactDOM.render(<MainApp config={config} runtimeMode={"spa"} />, el);
        }
      })
      .catch(e => {
        console.log(e);
        alert(`Failed to start the app - config is missing.`);
      });
  });
