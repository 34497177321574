export const tagClickedSerchParams = (locationSearch: string, linkSlug: string, deleteAlreadyActiveTags: boolean) => {
  const s = new URLSearchParams(locationSearch);

  const allTags = s.getAll(TAGS_QS_NAME);
  if (allTags.includes(linkSlug)) {
    if (deleteAlreadyActiveTags) {
      s.delete(TAGS_QS_NAME);

      allTags
        .filter(t => t !== linkSlug)
        .forEach(t => {
          s.append(TAGS_QS_NAME, t);
        });
    }
  } else {
    s.append(TAGS_QS_NAME, linkSlug);
  }

  const sStr = s.toString();

  return sStr || undefined;
};

export const urlWithTagClicked = (
  locationPathname: string,
  locationSearch: string,
  linkSlug: string,
  deleteAlreadyActiveTags: boolean,
) => ({
  pathname: locationPathname,
  search: tagClickedSerchParams(locationSearch, linkSlug, deleteAlreadyActiveTags),
});

export const TAGS_QS_NAME = "tags";

export type TagT = { label: string; link: string };
export type ExpandedTagT = {
  label: string;
  link: string;
  expandedLink: { pathname: string; search: string | undefined };
};
export type TagExpanderF = (t: TagT) => ExpandedTagT;
