export const checkoutAddressFormFieldKey = {
  firstName: "first-name",
  lastName: "last-name",
  addressOne: "address-one",
  addressTwo: "address-two",
  postalCode: "postal-code",
  city: "city",
  state: "state",
  country: "country",
  phone: "phone",
  email: "email",
};
