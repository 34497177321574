// If there's something like this in fp-ts, let's use it instead!

import { pipe } from "fp-ts/lib/function";
import type { TaskEither } from "fp-ts/TaskEither";
import * as TE from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import * as I from "fp-ts/lib/IO";

export function retry<T, E = unknown>(
  eff: () => Promise<T>,
  retryPolicy: {
    count: number;
    delayMs: number;
    tapError?: (err: E, leftRetryAttempts: number) => void;
    tapRetry?: (err: unknown) => void;
  },
): () => Promise<T> {
  return () =>
    eff().catch(err => {
      if (retryPolicy.count <= 0) {
        return Promise.reject(err);
      } else {
        try {
          retryPolicy.tapError ? retryPolicy.tapError(err, retryPolicy.count) : undefined;
        } catch (e) {
          retryPolicy.tapRetry ? retryPolicy.tapRetry(e) : undefined;
        }

        return delay(retry(eff, { ...retryPolicy, count: retryPolicy.count - 1 }), retryPolicy.delayMs)();
      }
    });
}

export function retryTE<T, E>(
  eff: TaskEither<E, T>,
  retryPolicy: {
    count: number;
    delayMs: number;
    tapError?: (err: E, leftRetryAttempts: number) => void;
  },
  logs?: {
    onStart?: I.IO<void>;
    onFinish?: I.IO<void>;
  },
): TaskEither<E, T> {
  return pipe(
    TE.Do,
    TE.bind("_", () => (logs?.onStart ? TE.fromIO(logs?.onStart) : TE.of(undefined))),
    TE.chain(() => eff),
    TE.fold(
      err => {
        if (retryPolicy.count <= 0) {
          return TE.left(err);
        } else {
          try {
            retryPolicy.tapError ? retryPolicy.tapError(err, retryPolicy.count) : undefined;
          } catch {
            //
          }

          return delayTE<T, E>(
            retryTE<T, E>(eff, { ...retryPolicy, count: retryPolicy.count - 1 }),
            retryPolicy.delayMs,
          );
        }
      },
      t => TE.right(t),
    ),
    T.map(res => {
      if (logs?.onFinish) {
        logs?.onFinish();
      }

      return res;
    }),
  );
}

export function delay<T>(eff: () => Promise<T>, delayMs: number): () => Promise<T> {
  return delayMs > 0 ? () => delayedPromise(delayMs)().then(eff) : eff;
}

export function delayTE<T, E>(eff: TaskEither<E, T>, delayMs: number): TaskEither<E, T> {
  return delayMs > 0
    ? pipe(
        delayedPromise(delayMs),
        TE.fromTask,
        TE.chain(() => eff),
      )
    : eff;
}

export function delayedPromise(delayMs: number): T.Task<undefined> {
  return () =>
    new Promise(res => {
      setTimeout(() => {
        res(undefined);
      }, delayMs);
    });
}

export function recoverablePromise<I, O>(
  eff: (i: I) => Promise<O>,
  i: I,
  recoverI: (e: unknown) => Promise<I>,
  maxRetries: number,
): () => Promise<O> {
  return () => {
    return eff(i).catch(e => {
      if (maxRetries > 0) {
        return recoverI(e).then(updatedI => recoverablePromise(eff, updatedI, recoverI, maxRetries - 1)());
      } else {
        throw e;
      }
    });
  };
}
