import { type SwarovskiOptikSegmentShort } from "../../domain/segments/segment";
import { type SwarovskiOptikLocale } from "../../domain/locale";
import { getIsoCodeWithIntFallback } from "../../domain/locale";

type Locale =
  | {
      countryIsoCode: string;
      languageIsoCode: string;
    }
  | Omit<SwarovskiOptikLocale, "contentful">;

// These are quite critical and used in many important places
// Please take appropriate care when introducing changes here
// as it may break many (important) places...

export class LocalePathSlug {
  static fromSwarovskiLocale(locale: Locale): string {
    let countryIsoCode = "";
    let languageIsoCode = "";

    if ("countryIsoCode" in locale && "languageIsoCode" in locale) {
      countryIsoCode = locale.countryIsoCode;
      languageIsoCode = locale.languageIsoCode;
    } else {
      countryIsoCode = getIsoCodeWithIntFallback(locale.country);
      languageIsoCode = locale.language.isoCode;
    }

    const countryPath = LocalePathSlug.getCountryPath(countryIsoCode);

    return `/${countryPath}/${languageIsoCode.toLowerCase()}`;
  }

  private static getCountryPath(countryIsoCode: string): string {
    return countryIsoCode.toLowerCase();
  }
}

export class SwagSlugBuilder {
  static segmentPathObj(locale: Locale, segment: SwarovskiOptikSegmentShort): string {
    const localeSlug = LocalePathSlug.fromSwarovskiLocale(locale);

    return `${localeSlug}/${segment.slug}`;
  }

  static path(locale: Locale, segment: SwarovskiOptikSegmentShort, slug: string): string {
    const s = SwagSlugBuilder.segmentPathObj(locale, segment);

    return `${s}/${slug}`;
  }
}
