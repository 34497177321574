import { nonEmpty } from "../../utils";

export class AddressFormatter {
  static formatAddress(
    address: {
      city: string | null | undefined;
      state:
        | {
            isoCode: string | null | undefined;
            label: string | null | undefined;
          }
        | null
        | undefined;
      postalCode: string | null | undefined;
      street: string | null | undefined;
    },
    _countryIsoCode: string,
  ) {
    const { street, city, postalCode, state } = address;
    const countryIsoCode = _countryIsoCode.toUpperCase();

    function formattedPostalCode(code: string | null | undefined) {
      if (countryIsoCode !== "US") {
        return code;
      }

      return code ? code.split("-")[0] : code;
    }

    if (countryIsoCode === "US" || countryIsoCode === "CA") {
      const secondLine = [city, [state?.isoCode, formattedPostalCode(postalCode)].filter(nonEmpty).join(" ")]
        .filter(nonEmpty)
        .join(", ");

      return [street, secondLine].filter(nonEmpty);
    } else if (countryIsoCode === "GB") {
      return [street, city ? city.toUpperCase() : "", postalCode ? postalCode.toUpperCase() : ""].filter(nonEmpty);
    } else {
      return [street, [postalCode, city].filter(nonEmpty).join(" ")].filter(nonEmpty);
    }
  }
}
