import { PublicConfigurationT, retry } from "@swo/shared";
import { fetchConfiguration } from "../../components/cms/fetch-configuration";

export function getWindowConfig(): Promise<PublicConfigurationT> {
  return (window as any).reactAppConfig
    ? Promise.resolve((window as any).reactAppConfig)
    : (() => {
        console.log(`Using local environment configuration (process.env)`);

        return retry(fetchConfiguration, {
          count: process.env.NODE_ENV === "development" ? Number.MAX_SAFE_INTEGER : 3,
          delayMs: 2000,
        })();
      })();
}
