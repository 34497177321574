import * as t from "io-ts";

const AssetLink = t.type({
  sys: t.type({
    linkType: t.literal("Asset"),
    type: t.literal("Link"),
    id: t.string,
  }),
});

const EntryLink = t.type({
  sys: t.type({
    linkType: t.literal("Entry"),
    type: t.literal("Link"),
    id: t.string,
  }),
});

export const KeyValuePairs = t.array(
  t.type({
    key: t.union([t.string, t.undefined, t.null]),
    value: t.union([t.string, t.undefined, t.null]),
  }),
);
export type KeyValuePairsT = t.TypeOf<typeof KeyValuePairs>;

const ContentfulReferenceEntryType = t.type({
  type: t.union([
    t.literal("PageStandardGlobal"),
    t.literal("PageStoriesOverviewGlobal"),
    t.literal("PageStoryGlobal"),
    t.literal("PageVideoStoriesOverviewGlobal"),
    t.literal("PageVideoStoryGlobal"),
    t.literal("PressReleasesPage"),
    t.literal("PressReleasePage"),
    t.literal("NewsletterLandingPage"),
    t.literal("DealerLocatorPage"),
    t.literal("SearchPage"),
    t.literal("CartPage"),
    t.literal("CheckoutPage"),
    t.literal("PurchaseConfirmationPage"),
    t.literal("PageIntendedUseGlobal"),
  ]),
  internalName: t.union([t.string, t.undefined, t.null]),
  contentfulReference: EntryLink,
  tags: t.union([t.array(EntryLink), t.null, t.undefined]),
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  segment: t.union([EntryLink, t.undefined, t.null]),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type ContentfulReferenceEntryTypeT = t.TypeOf<typeof ContentfulReferenceEntryType>;

const CommercetoolsCategoryEntryType = t.type({
  type: t.union([
    t.literal("ProductComparisonPage"),
    t.literal("PageProductListingGlobal"),
    t.literal("PageProductFamilyGlobal"),
  ]),
  internalName: t.union([t.string, t.undefined, t.null]),
  categoryKey: t.string,
  familyKey: t.union([t.string, t.null, t.undefined]),
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  segment: t.union([EntryLink, t.undefined, t.null]),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type CommercetoolsCategoryEntryTypeT = t.TypeOf<typeof CommercetoolsCategoryEntryType>;

const CommercetoolsProductEntryType = t.type({
  type: t.literal("ProductDetailPage"),
  internalName: t.union([t.string, t.undefined, t.null]),
  productKey: t.string,
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  segment: t.union([EntryLink, t.undefined, t.null]),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type CommercetoolsProductEntryTypeT = t.TypeOf<typeof CommercetoolsProductEntryType>;

const CommercetoolsProductVariantEntryType = t.type({
  type: t.literal("ProductVariantDetailPage"),
  internalName: t.union([t.string, t.undefined, t.null]),
  productVariantKey: t.string,
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
  segment: t.union([EntryLink, t.undefined, t.null]),
});

export type CommercetoolsProductVariantEntryTypeT = t.TypeOf<typeof CommercetoolsProductVariantEntryType>;

const RawIdEntryType = t.type({
  type: t.union([t.literal("EventsOverviewPage"), t.literal("DealerPage"), t.literal("EventPage")]),
  internalName: t.union([t.string, t.undefined, t.null]),
  rawPageId: t.string,
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  segment: t.union([EntryLink, t.undefined, t.null]),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type RawIdEntryTypeT = t.TypeOf<typeof RawIdEntryType>;

const ExternalLinkEntryType = t.type({
  type: t.literal("External"),
  internalName: t.union([t.string, t.undefined, t.null]),
  externalLink: t.union([t.string, t.undefined, t.null]),
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type ExternalLinkEntryTypeT = t.TypeOf<typeof ExternalLinkEntryType>;

const AssetLinkEntryType = t.type({
  type: t.literal("Asset"),
  internalName: t.union([t.string, t.undefined, t.null]),
  label: t.record(t.string, t.union([t.string, t.undefined, t.null])),
  asset: t.union([AssetLink, t.undefined, t.null]),
  queryParameters: t.union([KeyValuePairs, t.undefined, t.null]),
});

export type AssetLinkEntryTypeT = t.TypeOf<typeof AssetLinkEntryType>;

export const UtilityLinkEntry = t.union([
  ContentfulReferenceEntryType,
  CommercetoolsCategoryEntryType,
  CommercetoolsProductEntryType,
  CommercetoolsProductVariantEntryType,
  RawIdEntryType,
  ExternalLinkEntryType,
  AssetLinkEntryType,
]);

export type UtilityLinkEntryT = t.TypeOf<typeof UtilityLinkEntry>;
