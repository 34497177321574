import { array, number } from "fp-ts";
import { option } from "fp-ts";
import type { TypedMoney } from "@commercetools/platform-sdk";
import type { Option } from "fp-ts/Option";
import { type Ord, contramap } from "fp-ts/Ord";
import { pipe } from "fp-ts/lib/function";
import type { NonEmptyArray } from "fp-ts/es6/NonEmptyArray";

export function getProductStartingPrice(product: {
  variants: NonEmptyArray<{ price?: TypedMoney | undefined }>;
}): Option<TypedMoney> {
  return pipe(
    product.variants,
    array.filterMap(v => option.fromNullable(v.price)),
    array.sort(productVariantPriceOrd),
    array.head,
  );
}

const productVariantPriceOrd: Ord<TypedMoney> = contramap((variantPrice: TypedMoney) => variantPrice.centAmount)(
  number.Ord,
);
