import type { Ord } from "fp-ts/Ord";

export function padNumbersInString(str: string, digiLength: number): string {
  let match;

  let strM = str;
  let acc = "";

  while ((match = /([\d\.]+)/gm.exec(strM)) != null) {
    const [matchedString] = match;
    const index = match.index;

    const matchedNumber = Math.round(Number.parseFloat(matchedString) * 100);

    acc += strM.slice(0, index);
    acc += matchedNumber.toString().padStart(digiLength, "0");

    strM = strM.slice(index + matchedString.length);
  }

  return acc.concat(strM);
}

export const productNameSort = (a: string, b: string) => {
  const digiLength = 7;

  return padNumbersInString(a, digiLength).localeCompare(padNumbersInString(b, digiLength));
};

export const productNameOrd: Ord<string> = {
  equals: (a, b) => a.toLowerCase() === b.toLowerCase(),
  compare: (x, y) => {
    const r = productNameSort(x, y);

    if (r === 1) {
      return 1;
    } else if (r === -1) {
      return -1;
    } else {
      return 0;
    }
  },
};
