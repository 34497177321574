import cn from "classnames";

// It's not convenient to have it in here wrt to frontend development
// Move it to frontend and export from frontend (to use in tests)

export const jsHooks = {
  headerNavigationContent: "js-header-navigation js-header-navigation-content",
  headerNavigationProducts: "js-header-navigation js-header-navigation-products",
  heroImageCta: "js-hero-image-cta",
  localeSuggestionOverlay: "js-locale-suggestion-overlay",
  localeSuggestionOverlayAccept: "js-locale-suggestion-overlay--accept",
  cartDiscountCodeRemove: "js-cart-discount-code-remove",
  pdpBar: (isPrimary: boolean) => (isPrimary ? `js-pdp-bar js-pdp-bar-primary` : `js-pdp-bar js-pdp-bar-secondary`),
  pageType: (pageType: string) => cn(["js-page", `js-page-${pageType}`]),
  cartLineItem: {
    remove: "js-cart-line-item-remove",
    quantity: {
      increase: "js-line-item-quantity-increase",
      decrease: "js-line-item-quantity-decrease",
    },
  },
  checkout: {
    pageLink: "js-cart-go-to-checkout",
    asRegisteredUser: "js-checkout-buy-as-registered-user",
    asAGuest: "js-checkout-buy-as-guest",
    shippingMethods: {
      list: "js-checkout-shipping-methods-list",
      element: "js-checkout-shipping-methods-element",
    },
    addressForm: {
      form: "js-checkout-address-form",
      field: (fieldKey: string) => cn("js-checkout-address-field", `js-checkout-address-field-${fieldKey}`),
      save: "js-checkout-address-form-save",
    },
    paymentMethods: {
      list: "js-checkout-payment-methods-list",
      method: (methodKey: string) => cn("js-checkout-payment-method", `js-checkout-payment-method-${methodKey}`),

      creditCard: {
        iframeWrapper: "js-checkout-payment-method-credit-card--iframe-wrapper",
        ccNumber: "js-checkout-payment-method-credit-card--number",
        expiryDate: "js-checkout-payment-method-credit-card--expiry-date",
        securityCode: "js-checkout-payment-method-credit-card--security-code",
        save: "js-checkout-payment-method-credit-card--save",
      },
    },
    termsAndConditionsCheckbox: "js-checkout-terms-and-conditions-checkbox",
    placeOrderButton: "js-checkout-place-order",
  },
  purchaseConfirmationPage: {
    success: {
      headline: "js-pcp-success-headline",
      customerName: "js-pcp-success-customer-name",
    },
  },
};
