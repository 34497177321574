import type { VariantAttribute } from "./products";

export function compareAttributes(one: VariantAttribute, another: VariantAttribute): boolean {
  if (one.name !== another.name) {
    return false;
  } else if (one.type !== another.type) {
    return false;
  } else if (typeof one.value === "undefined" && typeof another.value === "undefined") {
    return true;
  } else if (typeof one.value === "undefined") {
    return false;
  } else if (typeof another.value === "undefined") {
    return false;
  } else if (one.type === "color" && another.type === "color") {
    return one.value.colorKey === another.value.colorKey;
  } else if (one.type === "clothing-size" && another.type === "clothing-size") {
    return one.value.data === another.value.data;
  } else if (one.type === "reticle" && another.type === "reticle") {
    return one.value.reticleKey === another.value.reticleKey;
  } else if (one.type === "product variant" && another.type === "product variant") {
    return one.value.sku === another.value.sku;
  } else if (one.type === "price-only" && another.type === "price-only") {
    return one.value.data === another.value.data;
  } else if (one.type === "plain" && another.type === "plain") {
    if (one.value.type === "text" && another.value.type === "text") {
      return one.value.data === another.value.data;
    } else if (one.value.type === "number" && another.value.type === "number") {
      return one.value.data === another.value.data;
    } else if (one.value.type === "boolean" && another.value.type === "boolean") {
      return one.value.data === another.value.data;
    } else if (one.value.type === "enum" && another.value.type === "enum") {
      return one.value.data.key === another.value.data.key;
    } else {
      return JSON.stringify(one.value.data) === JSON.stringify(another.value.data);
    }
  } else {
    return false;
  }
}
